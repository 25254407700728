import React, { useEffect, useState } from "react";
import useStyles, { FooterContainer, NavbarLogo, NavbarLink } from "./styles";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Box, Container, Dialog, DialogContent, Grid } from "@mui/material";
import Section from "../Section/Section";
import Paragraph from "../Typography/Paragraph";
import FormInputNoLabel from "../FormInputNoLabel/FormInputNoLabel";
import {
  Mail,
  Phone,
  Storefront,
  Facebook,
  Instagram,
  Twitter,
  Room,
  Close,
} from "@mui/icons-material";

import logo from "../../assets/images/logo.png";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import whatapps from "../../assets/images/whatapps.png";
import tiktok from "../../assets/images/tiktok.png";
import linktree from "../../assets/images/linktree.png";

import Loader from "react-js-loader";
import toast from "react-simple-toasts";
import Button from "../Button/Button";

import { subscribe } from "../../API/API";
import FormInput from "../FormInput/FormInput";

export default function Footer() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [openSub, setOpenSub] = React.useState(false);
  const [subData, setSubData] = React.useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    setSubData({
      ...subData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <React.Fragment>
      <FooterContainer>
        <Container>
          <Section pd="0">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <div className={classes.theLogo}>
                  <img src={logo} />
                  {/* <Paragraph color="#125396" size="14px" center>Registration Number: 899604-P</Paragraph> */}
                </div>
                <div style={{ display: "flex", marginBottom: "1rem" }}>
                  <Room style={{ color: "#125396" }} />
                  <Paragraph color="#125396" margin="0 0 0 1rem" size="14px">
                    2, Jalan Wawasan 3, Kawasan Perindustrian Sri Gading 83300
                    Sri Gading, Batu Pahat, Johor, Malaysia
                  </Paragraph>
                </div>
                <a
                  href="tel:+60122361553"
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                  }}
                >
                  <Phone style={{ color: "#125396" }} />
                  <Paragraph
                    color="#125396"
                    margin="0 0 .4rem 1rem"
                    size="14px"
                  >
                    +6012-2361553
                  </Paragraph>
                </a>
                <a
                  href="mailto: sales@megahtex.com"
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                  }}
                >
                  <Mail style={{ color: "#125396" }} />
                  <Paragraph color="#125396" margin="0 0 0 1rem" size="14px">
                    sales@megahtex.com
                  </Paragraph>
                </a>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <Paragraph margin="0 0 1rem 0" color="#125396" bold="600">
                  Service
                </Paragraph>

                <a
                  style={{
                    color: "#125396",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "14px",
                  }}
                  href="/readytowear"
                >
                  Ready to Wear
                </a>
                <a
                  style={{
                    color: "#125396",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "14px",
                  }}
                  href="/custommade"
                >
                  Custom Made
                </a>
                <a
                  style={{
                    color: "#125396",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "14px",
                  }}
                  href="/decorative"
                >
                  Decorative
                </a>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <Paragraph margin="0 0 1rem 0" color="#125396" bold="600">
                  Company
                </Paragraph>

                <a
                  style={{
                    color: "#125396",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "14px",
                  }}
                  href="/about"
                >
                  About Us
                </a>
                <a
                  style={{
                    color: "#125396",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "14px",
                  }}
                  href="/about"
                >
                  Our Client
                </a>
                <a
                  style={{
                    color: "#125396",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "14px",
                  }}
                  href="/showroom"
                >
                  Showroom
                </a>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <Paragraph margin="0 0 1rem 0" color="#125396" bold="600">
                  Quick Links
                </Paragraph>

                <a
                  style={{
                    color: "#125396",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "14px",
                  }}
                  href="/account"
                >
                  My Account
                </a>
                <a
                  style={{
                    color: "#125396",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "14px",
                  }}
                  href="/orderhistory"
                >
                  Sales Order
                </a>
                <a
                  style={{
                    color: "#125396",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "14px",
                  }}
                  href="/account"
                >
                  Credit Limit
                </a>
                <a
                  style={{
                    color: "#125396",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "14px",
                  }}
                  href="/orderhistory"
                >
                  Bulk Order
                </a>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <Paragraph margin="0 0 1rem 0" color="#125396" bold="600">
                  Follow us
                </Paragraph>
                {/* <div style={{ display: "flex" }}>
                  <a href="https://www.facebook.com/">
                    <Facebook style={{ color: "#125396" }} />
                  </a>
                  <a href="https://www.instagram.com/">
                    <Instagram
                      style={{ color: "#125396", margin: "0 .3rem" }}
                    />
                  </a>
                  <a href="https://www.twitter.com/">
                    <Twitter style={{ color: "#125396" }} />
                  </a>
                </div> */}
                <div style={{ display: "flex", marginBottom: "1rem" }}>
                  <a
                    href="https://www.facebook.com/megahtextilemy"
                    target="_blank"
                  >
                    {/* <Facebook style={{ color: "#194234" }} /> */}
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "12px",
                      }}
                      src={facebook}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/megahtextile.my/?hl=en"
                    target="_blank"
                  >
                    {/* <Instagram
                          style={{ color: "#194234", margin: "0 0 0 1rem" }}
                        /> */}
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "12px",
                      }}
                      src={instagram}
                    />
                  </a>

                  <a
                    href="https://www.tiktok.com/@gildanmalaysia"
                    target="_blank"
                  >
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "12px",
                      }}
                      src={tiktok}
                    />
                  </a>
                  <a href="https://linktr.ee/GildanMalaysia" target="_blank">
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "12px",
                      }}
                      src={linktree}
                    />
                  </a>
                </div>

                <Box>
                  <img style={{width: "150px"}} src="../assets/images/btm.png" />
                </Box>
                {/* <Paragraph
                  color="#125396"
                  bold="600"
                  onClick={() => setOpenSub(true)}
                >
                  Subscribe Now
                </Paragraph> */}
              </Grid>
            </Grid>
            <Box sx={{margin: "1rem 0 0 0"}}>
              <Paragraph bold="400" size="15px" center>© 2025 Megahtextile. All rights reserved. Megah Textile Sdn Bhd (201001015363 (899604-P)) </Paragraph>
            </Box>
          </Section>
        </Container>
      </FooterContainer>
      <Dialog
        open={openSub}
        onClose={() => setOpenSub(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenSub(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <Paragraph size="24px" bold="600" color="#2a2866">
              Subscribe to Our Newsletter
            </Paragraph>
            <Paragraph margin="0  0 1.5rem 0">
              Sign up to receive our latest news!
            </Paragraph>
            <FormInputNoLabel
              placeholder="Name *"
              name="name"
              // bg="#D7D7D7"
              border="1px solid #D7D7D7"
              br="0px"
              onChange={handleChange}
              style={{ marginBottom: "1rem" }}
            />
            <FormInputNoLabel
              placeholder="Email *"
              name="email"
              border="1px solid #D7D7D7"
              br="0px"
              onChange={handleChange}
              style={{ marginBottom: "0" }}
            />

            <Button
              label="SUBSCRIBE"
              color="#fff"
              bg="#125396"
              hover
              br="6px"
              margin=".6rem 0 0 0"
              // onClick={() => handleAddAddress()}
            />
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
