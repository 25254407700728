import React from "react";
import {
  Container,
  Grid,
  Dialog,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles, { FormInputSelect } from "./styles";
import "./styles.css";
import {
  Person,
  Lock,
  Bookmark,
  Inbox,
  Close,
  Edit,
  Delete,
} from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import Button from "../../components/Button/Button";
import {
  add_address,
  delete_address,
  edit_address,
  edit_profile,
  get_address,
  get_address_detail,
  get_orders_history,
  get_customer_profile,
  change_password,
  topup,
  process_order,
  get_topup_amount,
  get_statement,
} from "../../API/API";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import { useNavigate } from "react-router-dom";
import Loader from "react-js-loader";

export default function StatementAccount() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [searchKey, setSearchKey] = React.useState("");

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
  };

  // API

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    if (!token || "") {
      navigate("/login");
    }
  }, []);

  const [statementData, setStatementData] = React.useState({
    startdate: "",
    enddate: "",
  });

  const [chooseMonth, setChooseMonth] = React.useState("");

  const handleChooseMonth = (e) => {
    setChooseMonth(e.target.value);
  };

  const [chooseYear, setChooseYear] = React.useState("");

  const handleChooseYear = (e) => {
    setChooseYear(e.target.value);
  };

  const handleChange = (e) => {
    setStatementData({
      ...statementData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    if (chooseMonth == "") {
      alert("Please Choose a Month");
      return;
    }

    if (chooseYear == "") {
      alert("Please Choose a Year");
      return;
    }

    // if (
    //   statementData.startdate.length == "" ||
    //   statementData.enddate.length == ""
    // ) {
    //   alert("All Field Required");
    //   return;
    // }
    get_statement({ token: token, month: chooseMonth, year: chooseYear }).then(
      (json) => {
        if (json.status) {
          window.open(json.data);
        } else {
          alert(json.message);
        }
      }
    );
  };

  return (
    <React.Fragment>
      <Navbar />
      <div style={{ background: "#e0f0f7", padding: "2rem 0 0 0" }}>
        <Container>
          <Paragraph color="#000" size="22px" bold="600" margin="0 0 1rem 0">
            My Account
          </Paragraph>
          <div className={classes.theOverScroll}>
            <a
              // className={tab == 0 ? classes.theLActive : classes.theL}
              // onClick={() => handleChangeTab(0)}
              className={classes.theL}
              href="/account"
            >
              <Paragraph
                style={{ padding: ".4rem 2rem", width: "max-content" }}
              >
                My Profile
              </Paragraph>
            </a>
            <a className={classes.theL} href="/balance">
              <Paragraph
                style={{ padding: ".4rem 2rem", width: "max-content" }}
              >
                My Balance
              </Paragraph>
            </a>
            <a
              className={classes.theL}
              //   onClick={() => handleChangeTab(2)}
              href="/orderhistory"
            >
              <Paragraph
                style={{ padding: ".4rem 2rem", width: "max-content" }}
              >
                My Order
              </Paragraph>
            </a>
            <a
              // className={tab == 3 ? classes.theLActive : classes.theL}
              // onClick={() => handleChangeTab(3)}
              className={classes.theL}
              href="/address"
            >
              <Paragraph
                style={{ padding: ".4rem 2rem", width: "max-content" }}
              >
                Address Book
              </Paragraph>
            </a>
            <a className={classes.theLActive} href="/statementaccount">
              <Paragraph
                color="#FFF"
                style={{ padding: ".4rem 2rem", width: "max-content" }}
              >
                Statement of Account
              </Paragraph>
            </a>
          </div>
        </Container>
      </div>
      <Container>
        <Section pd="2rem 0">
          <div>
            <>
              <div className={classes.theBox}>
                <Paragraph size="20px" bold="600" color="#878787">
                  Please select desire month & year to print your Statement
                </Paragraph>
                <Paragraph margin=" 1rem 0">
                  Select Month{" "}
                  <span style={{ color: "#F92000", marginLeft: "4px" }}>*</span>
                </Paragraph>
                <FormInputSelect>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={chooseMonth}
                    onChange={handleChooseMonth}
                    style={{ marginBottom: "1rem" }}
                  >
                    <MenuItem value="" style={{ fontWeight: "400" }}>
                      <em style={{ color: "#878787" }}>
                        Please select a month
                      </em>
                    </MenuItem>
                    <MenuItem value="01" style={{ color: "#000" }}>
                      January
                    </MenuItem>
                    <MenuItem value="02" style={{ color: "#000" }}>
                      February
                    </MenuItem>
                    <MenuItem value="03" style={{ color: "#000" }}>
                      March
                    </MenuItem>
                    <MenuItem value="04" style={{ color: "#000" }}>
                      April
                    </MenuItem>
                    <MenuItem value="05" style={{ color: "#000" }}>
                      May
                    </MenuItem>
                    <MenuItem value="06" style={{ color: "#000" }}>
                      June
                    </MenuItem>
                    <MenuItem value="07" style={{ color: "#000" }}>
                      July
                    </MenuItem>
                    <MenuItem value="08" style={{ color: "#000" }}>
                      August
                    </MenuItem>
                    <MenuItem value="09" style={{ color: "#000" }}>
                      September
                    </MenuItem>
                    <MenuItem value="10" style={{ color: "#000" }}>
                      October
                    </MenuItem>
                    <MenuItem value="11" style={{ color: "#000" }}>
                      November
                    </MenuItem>
                    <MenuItem value="12" style={{ color: "#000" }}>
                      December
                    </MenuItem>
                  </Select>
                </FormInputSelect>
               
                <Paragraph margin="0 0 1rem 0">
                  Select Year{" "}
                  <span style={{ color: "#F92000", marginLeft: "4px" }}>*</span>
                </Paragraph>
                <FormInputSelect>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={chooseYear}
                    onChange={handleChooseYear}
                    style={{ marginBottom: "1rem" }}
                  >
                    <MenuItem value="" style={{ fontWeight: "400" }}>
                      <em style={{ color: "#878787" }}>Please select a year</em>
                    </MenuItem>
                    <MenuItem value="2020" style={{ color: "#000" }}>
                      2020
                    </MenuItem>
                    <MenuItem value="2021" style={{ color: "#000" }}>
                      2021
                    </MenuItem>
                    <MenuItem value="2022" style={{ color: "#000" }}>
                      2022
                    </MenuItem>
                    <MenuItem value="2023" style={{ color: "#000" }}>
                      2023
                    </MenuItem>
                    <MenuItem value="2024" style={{ color: "#000" }}>
                      2024
                    </MenuItem>
                    <MenuItem value="2025" style={{ color: "#000" }}>
                      2025
                    </MenuItem>
                  </Select>
                </FormInputSelect>
                <br />
                <div className={classes.theSizeBtn}>
                  <Button
                    label="SUBMIT"
                    color="#fff"
                    bg="#125396"
                    bold="600"
                    hover
                    onClick={() => handleSubmit()}
                  />
                </div>
              </div>
            </>
          </div>
        </Section>
      </Container>
    </React.Fragment>
  );
}
