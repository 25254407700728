import React from "react";
import Slider from "react-slick";
import useStyles, { IncrementAction, IncrementAction2 } from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel2 from "../../components/FormInputNoLabel2/FormInputNoLabel2";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import silestone001 from "../../assets/images/silestone001.png";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Dialog,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import Button from "../../components/Button/Button";
import {
  Add,
  ArrowBackIos,
  ArrowDropDown,
  ArrowForwardIos,
  Close,
  Remove,
  ShoppingCart,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  create_sales_order,
  get_customer_profile,
  get_item_detail,
  validate_cart,
} from "../../API/API";
import useCart from "../../hooks/useCart";
import "./styles.css";
import Loader from "react-js-loader";

export default function ShopDetail() {
  const classes = useStyles();
  const navigate = useNavigate();
  let { item_id } = useParams();

  const { getCart } = useCart();

  const getcart = JSON.stringify(getCart());
  const [cart, setCart] = React.useState(getCart);

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    if (!token || "") {
      navigate("/login");
    }
  }, []);

  const [count, setCount] = React.useState(1);

  const handleChangeQty = (new_qty, index) => {
    if (new_qty < 1) {
      return;
    }
    setCount(new_qty);
  };

  const [expanded, setExpanded] = React.useState(1);

  const handleChangex = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // API

  const [chooseSize, setChooseSize] = React.useState(0);

  const handleChooseSize = (size_id) => {
    setChooseSize(size_id);
    // console.log(size_id);
  };

  const [chooseColour, setChooseColour] = React.useState([]);

  const handleChooseColour = (colour_id) => {
    setChooseColour(colour_id);
    // console.log(colour_id);
  };

  const [loading, setLoading] = React.useState(false);
  const [productData, setProductData] = React.useState([]);
  const [productMain, setProductMain] = React.useState([]);
  const [productImage, setProductImage] = React.useState("");
  const [tabsPhoto, setTabsPhoto] = React.useState(0);
  const [prodIMG, setProdIMG] = React.useState("");
  const [childIMG, setChildIMG] = React.useState("");
  const [showBestBuy, setShowBestBuy] = React.useState([]);

  const handleChangeTabPhoto = (text) => {
    setTabsPhoto(text);
  };

  const getProductData = () => {
    if (!token) {
      navigate("/login");
    } else {
      setLoading(true);
      get_item_detail({ item_id: item_id }).then((json) => {
        setProductData(json.data);
        setProductImage(json.data.image.image);
        setProductMain(json.data.children);
        setLoading(false);
        if (json.data.image != []) {
          setTabsPhoto(json.data.image[0].image);
        } else {
          setLoading(false);
        }

        for (var i = 0; i < json.data.children.length; i++) {
          let arr = json.data.children[i];

          // console.log(JSON.stringify(arr))
          // setShowBestBuy(JSON.stringify(arr))
          for (var j = 0; j < arr.length; j++) {
            // let x = [],
            setShowBestBuy([JSON.stringify(arr[j])]);
            // console.log(arr[j]);
            if (arr[j].image != []) {
              setProdIMG(arr[j].image);
            } else {
              setProdIMG(null);
            }
          }
        }
      });
    }
  };

  React.useEffect(() => {
    getProductData();
  }, []);

  const [itemData, setItemData] = React.useState([]);

  // const handleChange = (e, index, item) => {
  //   let list = [...itemData];

  //   var data = e.target.id;
  //   var datasplit = data.split("_");
  //   var id = datasplit[0];
  //   var color = datasplit[1];
  //   var size = datasplit[2];
  //   var price = datasplit[3];
  //   var single_price = datasplit[4];
  //   var image = datasplit[6];
  //   var stock = datasplit[7];

  //   var quantity = e.target.value !== "" ? parseInt(e.target.value) : 0;

  //   if (quantity > 0) {
  //     var data = {
  //       internalid: id,
  //       quantity: quantity,
  //       color: color,
  //       size: size,
  //       price: parseFloat(price * quantity).toFixed(2),
  //       single_price: parseFloat(price).toFixed(2),
  //       image: image,
  //       stock: stock,
  //       invalid: 0,
  //     };

  //     var existed = 0;

  //     for (var i = 0; i < list.length; i++) {
  //       if (id == list[i].internalid) {
  //         existed = 1;
  //         list[i] = data;
  //       }
  //     }

  //     if (existed == 0 && data.quantity >= 1) {
  //       data["index"] = list.length;
  //       list.push(data);
  //     }
  //   } else {
  //     for (var i = 0; i < list.length; i++) {
  //       if (id == list[i].internalid) {
  //         list.splice(i, 1);
  //       }
  //     }
  //   }

  //   setItemData(list);
  //   console.log(list);
  // };
  // ADD TO CART

  const [currentData, setCurrentData] = React.useState([]);

  const calculateCart = async () => {
    let postparam = {
      item: JSON.stringify(itemData),
      token: token,
    };

    var res = await validate_cart(postparam);
    if (res.status) {
      setCurrentData(res.data);
      window.localStorage.setItem("cart", JSON.stringify(res.data));
    } else {
      alert(res.message);
    }
  };

  const { addProduct, clearCart } = useCart();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChange = (e, index, item) => {
    if (!item) {
      console.error("Item is undefined");
      return;
    }

    let list = [...itemData];

    // Extract item properties
    var id = item.internalid;
    var size = item.size;
    var price = item.price;
    var single_price = item.single_price;
    var image = item.image;
    var stock = item.quantityavailable;

    // Process quantity
    var quantity = e.target.value !== "" ? parseInt(e.target.value) : 0;

    var dataToUpdate = {
      internalid: id,
      size: size,
      price: parseFloat(price * quantity).toFixed(2),
      single_price: parseFloat(price).toFixed(2),
      image: image,
      stock: stock,
      quantity: quantity,
      invalid: 0,
    };

    // console.log(dataToUpdate);

    var existingCartItem = list.find((cartItem) => cartItem.internalid === id);

    if (existingCartItem) {
      existingCartItem.quantity = quantity;
      existingCartItem.price = parseFloat(price * quantity).toFixed(2);
    } else if (quantity > 0) {
      dataToUpdate.index = list.length;
      list.push(dataToUpdate);
    }

    setItemData(list);
  };

  // const handleAddToCart = () => {
  //   console.log("ITEMDATA", itemData);

  //   // console.log(currentData)
  //   // console.log(itemData)
  //   let checkCurrentStock = false;

  //   if (currentData && currentData.length > 0) {
  //     checkCurrentStock = currentData.some(
  //       (item) => parseInt(item.quantity) > parseInt(item.stock)
  //     );
  //   } else if (itemData && itemData.length > 0) {
  //     checkCurrentStock = itemData.some((item) => item.quantity > item.stock);
  //   }

  //   // console.log(checkCurrentStock);
  //   if (checkCurrentStock) {
  //     alert("Please Check the Stock Available");
  //     setOpenModal(false);
  //   } else {
  //     const cartData = JSON.parse(localStorage.getItem("cart")) || [];

  //     for (const cartItem of cartData) {
  //       const matchingItem = itemData.find(
  //         (item) => item.internalid === cartItem.internalid
  //       );
  //       if (matchingItem) {
  //         cartItem.quantity += matchingItem.quantity;
  //       }
  //     }

  //     for (const newItem of itemData) {
  //       const existingCartItem = cartData.find(
  //         (item) => item.internalid === newItem.internalid
  //       );
  //       if (!existingCartItem) {
  //         cartData.push(newItem);
  //       }
  //     }

  //     localStorage.setItem("cart", JSON.stringify(cartData));

  //     let postparam = {
  //       item: JSON.stringify(cartData),
  //       token: token,
  //     };

  //     // console.log(postparam);

  //     validate_cart(postparam).then((res) => {
  //       if (res.status) {
  //         setCurrentData(res.data);

  //         // console.log(res.data);
  //         setOpenModal(true);
  //       } else {
  //         alert(res.message);
  //       }
  //     });
  //   }
  // };

  const handleAddToCart = () => {
    // console.log("ITEMDATA", itemData);

    // Filter out items with quantity 0
    const filteredItemData = itemData.filter((item) => item.quantity > 0);

    let checkCurrentStock = false;

    if (currentData && currentData.length > 0) {
      checkCurrentStock = currentData.some(
        (item) => parseInt(item.quantity) > parseInt(item.stock)
      );
    } else if (filteredItemData && filteredItemData.length > 0) {
      checkCurrentStock = filteredItemData.some(
        (item) => item.quantity > item.stock
      );
    }

    if (checkCurrentStock) {
      alert("Please Check the Stock Available");
      setOpenModal(false);
    } else {
      const cartData = JSON.parse(localStorage.getItem("cart")) || [];

      // Update quantities in the cart if item already exists
      for (const cartItem of cartData) {
        const matchingItem = filteredItemData.find(
          (item) => item.internalid === cartItem.internalid
        );
        if (matchingItem) {
          cartItem.quantity += matchingItem.quantity;
        }
      }

      // Add new items to the cart
      for (const newItem of filteredItemData) {
        const existingCartItem = cartData.find(
          (item) => item.internalid === newItem.internalid
        );
        if (!existingCartItem) {
          cartData.push(newItem);
        }
      }

      localStorage.setItem("cart", JSON.stringify(cartData));

      let postparam = {
        item: JSON.stringify(cartData),
        token: token,
      };

      validate_cart(postparam).then((res) => {
        if (res.status) {
          setCurrentData(res.data);
          setOpenModal(true);
        } else {
          alert(res.message);
        }
      });
    }
  };

  // OPEN MODAL CART

  const [openModal, setOpenModal] = React.useState(false);

  //
  const [checkCurrency, setCheckCurrency] = React.useState("");

  const getUser = () => {
    get_customer_profile({ token: token }).then((json) => {
      setCheckCurrency(json.data.currency);
    });
  };

  React.useEffect(() => {
    getUser();
  }, []);

  // const [newCartist, setNewCartList] = React.useState([]);
  // React.useEffect(() => {
  //   let a = window.localStorage.getItem("cart");
  //   setNewCartList(JSON.parse(a));
  // }, []);

  return (
    <React.Fragment>
      <Navbar />
      {loading == true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "600px",
          }}
        >
          <Loader type="snipper-default" bgColor={"#878787"} size={50} />
        </div>
      ) : (
        <Container>
          <Section pd="2rem 0">
            <div style={{ display: "flex", alignItems: "center" }}>
              <a onClick={() => navigate(-1)}>
                <Paragraph size="14px">Shop</Paragraph>
              </a>
              <ArrowForwardIos
                style={{ fontSize: "12px", margin: "0 .4rem" }}
              />
              <a onClick={() => navigate(-1)}>
                <Paragraph size="14px">{productData.brand}</Paragraph>
              </a>
              <ArrowForwardIos
                style={{ fontSize: "12px", margin: "0 .4rem" }}
              />
              <a href={`../shopdetail/${item_id}`}>
                <Paragraph size="14px">{productData.displayname}</Paragraph>
              </a>
            </div>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <div className={classes.theProdIMG}>
                  <img src={tabsPhoto} />
                </div>
                <br />
                {productData.image && (
                  <div className={classes.theGalleryList}>
                    {productData.image.map((item) => (
                      <div
                        className={classes.theGalleryx}
                        onClick={() => {
                          handleChangeTabPhoto(item.image);
                        }}
                      >
                        <img src={item.image} />
                      </div>
                    ))}
                  </div>
                )}

                <br />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Paragraph
                  color="#878787"
                  bold="500"
                  size="14px"
                  margin="0 0 .2rem 0"
                >
                  {productData.brand}
                </Paragraph>
                <Paragraph
                  color="#125396"
                  bold="500"
                  size="24px"
                  margin="0 0 2rem 0"
                >
                  {productData.displayname}
                </Paragraph>

                <Paragraph size="14px" margin="1rem 0">
                  {productData.salesdescription}
                </Paragraph>
              </Grid>
            </Grid>

            <Box sx={{ display: { xs: "none", md: "block" } }}>
              {productMain.map((item, index) => (
                <>
                  <div
                    style={{
                      position: "relative",
                      padding: "2rem 1rem 1rem 1rem",
                      border: "1px solid #d7d7d7",
                      overflow: "auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // position: "relative",
                      }}
                    >
                      <div
                        style={{
                          // width: "30%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        {item[0].image != "" ? (
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "contain",
                            }}
                            src={item[0].image}
                          />
                        ) : (
                          <></>
                        )}
                        <Box>
                          <Paragraph
                            color="#125396"
                            bold="500"
                            margin=".4rem 0 0 0 "
                            center
                          >
                            {item[0].catalogue}
                          </Paragraph>
                          <Paragraph
                            color="#125396"
                            bold="500"
                            margin=".4rem 0 0 0 "
                            center
                          >
                            {item[0].color}
                          </Paragraph>
                        </Box>
                      </div>
                      {item.map((item, index) => (
                        <Box
                          sx={{
                            margin: { xs: "0 1rem", md: "0 2.1rem 0 0" },
                          }}
                        >
                          {/* <img src={item.image}/> */}
                          {item.bestbuy == "1" && (
                            <Box
                              sx={{
                                width: "5%",
                                padding: ".2rem .6rem",
                                position: "absolute",
                                top: "12px",
                                left: "0",
                                zIndex: 1,
                              }}
                            >
                              <img
                                style={{ width: "100%" }}
                                src="../../assets/images/bestdeal.png"
                              />
                            </Box>
                          )}
                          <Paragraph
                            color="#125396"
                            bold="500"
                            margin="0 0 .4rem 0"
                            center
                          >
                            {item.size} - {item.catalogue}
                          </Paragraph>
                          {item.quantityavailable == 0 || item.price == 0 ? (
                            <>
                              <FormInputNoLabel2
                                disabled
                                border="1px solid #d7d7d7"
                                name="quantity"
                                onChange={(e) => handleChange(e, index)}
                                className="customTextField"
                                sx={{
                                  width: {
                                    xs: "150px",
                                    sm: "100px",
                                    lg: "100%",
                                  },
                                }}
                                id={
                                  item.internalid +
                                  "_" +
                                  item.size +
                                  "_" +
                                  item.price +
                                  "_" +
                                  item.single_price +
                                  "_" +
                                  productData.displayname +
                                  "_" +
                                  item.image +
                                  "_" +
                                  item.quantityavailable
                                }
                                value={item.quantity || null}
                                onKeyPress={(event) => {
                                  if (
                                    event.target.value == "" &&
                                    !/[1-9]/.test(event.key)
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              {checkCurrency == "MYR" && (
                                <Paragraph
                                  style={{ fontStyle: "italic" }}
                                  margin="0"
                                  bold="600"
                                  center
                                  color={
                                    item.bestbuy == "1" ? "#FF00FF" : "#000"
                                  }
                                >
                                  MYR {parseFloat(item.price).toFixed(2)}
                                </Paragraph>
                              )}

                              {checkCurrency == "SGD" && (
                                <Paragraph
                                  style={{ fontStyle: "italic" }}
                                  margin="0"
                                  bold="600"
                                  center
                                  color={
                                    item.bestbuy == "1" ? "#FF00FF" : "#000"
                                  }
                                >
                                  SGD {parseFloat(item.price).toFixed(2)}
                                </Paragraph>
                              )}

                              {checkCurrency == "USD" && (
                                <Paragraph
                                  style={{ fontStyle: "italic" }}
                                  margin="0"
                                  bold="600"
                                  center
                                  color={
                                    item.bestbuy == "1" ? "#FF00FF" : "#000"
                                  }
                                >
                                  USD {parseFloat(item.price).toFixed(2)}
                                </Paragraph>
                              )}

                              {item.quantityavailable == 0 && (
                                <Paragraph
                                  style={{ fontStyle: "italic" }}
                                  margin="0"
                                  center
                                  color="red"
                                >
                                  Out of Stock
                                </Paragraph>
                              )}

                              <input
                                value={item.internalid}
                                name="id"
                                onChange={(e) => handleChange(e, index)}
                                type="hidden"
                              />
                            </>
                          ) : (
                            <>
                              {item.enablepurchase > 0 ? (
                                <FormInputNoLabel2
                                  disabled={item.quantityavailable <= 0}
                                  border="1px solid #d7d7d7"
                                  name="quantity"
                                  onChange={(e) => handleChange(e, index, item)}
                                  className="customTextField"
                                  sx={{
                                    width: {
                                      xs: "150px",
                                      sm: "100px",
                                      lg: "100%",
                                    },
                                  }}
                                  id={
                                    item.internalid +
                                    "_" +
                                    item.size +
                                    "_" +
                                    item.price +
                                    "_" +
                                    item.single_price +
                                    "_" +
                                    productData.displayname +
                                    "_" +
                                    item.image +
                                    "_" +
                                    item.quantityavailable
                                  }
                                  value={item.quantity || null}
                                  onKeyPress={(event) => {
                                    if (
                                      event.target.value == "" &&
                                      !/[1-9]/.test(event.key)
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              ) : (
                                <>
                                  <FormInputNoLabel2
                                    disabled={item.quantityavailable < 10}
                                    border="1px solid #d7d7d7"
                                    name="quantity"
                                    onChange={(e) =>
                                      handleChange(e, index, item)
                                    }
                                    className="customTextField"
                                    sx={{
                                      width: {
                                        xs: "150px",
                                        sm: "100px",
                                        lg: "100%",
                                      },
                                    }}
                                    id={
                                      item.internalid +
                                      "_" +
                                      item.size +
                                      "_" +
                                      item.price +
                                      "_" +
                                      item.single_price +
                                      "_" +
                                      productData.displayname +
                                      "_" +
                                      item.image +
                                      "_" +
                                      item.quantityavailable
                                    }
                                    value={item.quantity || null}
                                    onKeyPress={(event) => {
                                      if (
                                        event.target.value == "" &&
                                        !/[1-9]/.test(event.key)
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </>
                              )}

                              {checkCurrency == "MYR" && (
                                <Paragraph
                                  style={{ fontStyle: "italic" }}
                                  margin="0"
                                  bold="600"
                                  center
                                  color={
                                    item.bestbuy == "1" ? "#FF00FF" : "#000"
                                  }
                                >
                                  MYR {parseFloat(item.price).toFixed(2)}
                                </Paragraph>
                              )}

                              {checkCurrency == "SGD" && (
                                <Paragraph
                                  style={{ fontStyle: "italic" }}
                                  margin="0"
                                  bold="600"
                                  center
                                  color={
                                    item.bestbuy == "1" ? "#FF00FF" : "#000"
                                  }
                                >
                                  SGD {parseFloat(item.price).toFixed(2)}
                                </Paragraph>
                              )}

                              {checkCurrency == "USD" && (
                                <Paragraph
                                  style={{ fontStyle: "italic" }}
                                  margin="0"
                                  bold="600"
                                  center
                                  color={
                                    item.bestbuy == "1" ? "#FF00FF" : "#000"
                                  }
                                >
                                  USD {parseFloat(item.price).toFixed(2)}
                                </Paragraph>
                              )}
                              {item.enablepurchase > 0 ? (
                                <Paragraph
                                  style={{ fontStyle: "italic" }}
                                  margin="0"
                                  center
                                  color="green"
                                >
                                  {item.quantityavailable} stocks left
                                </Paragraph>
                              ) : (
                                // <></>
                                <>
                                  {item.quantityavailable < 10 ? (
                                    <Paragraph
                                      style={{ fontStyle: "italic" }}
                                      margin="0"
                                      center
                                      color="red"
                                    >
                                      Call Customer Service
                                    </Paragraph>
                                  ) : (
                                    <Paragraph
                                      style={{ fontStyle: "italic" }}
                                      margin="0"
                                      center
                                      color="green"
                                    >
                                      {item.quantityavailable} stocks left
                                    </Paragraph>
                                  )}
                                </>
                              )}
                              {/* {item.quantityavailable == 0 ? (
                                  <Paragraph
                                    style={{ fontStyle: "italic" }}
                                    margin="0"
                                    center
                                    color="red"
                                  >
                                    Out of Stock
                                  </Paragraph>
                                ) : (
                                  <Paragraph
                                    style={{ fontStyle: "italic" }}
                                    margin="0"
                                    center
                                    color="green"
                                  >
                                    {item.quantityavailable} stocks left
                                  </Paragraph>
                                )} */}
                              <input
                                value={item.internalid}
                                name="id"
                                onChange={(e) => handleChange(e, index)}
                                type="hidden"
                              />
                            </>
                          )}
                        </Box>
                      ))}
                    </div>
                  </div>
                  <br />
                </>
              ))}
            </Box>
            <br />

            <Box sx={{ display: { xs: "block", md: "none" } }}>
              {productMain.map((item) => (
                <>
                  <Box
                    sx={{
                      padding: "1rem",
                      border: "1px solid #d7d7d7",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "end",
                        // alignItems: "flex-start",
                        margin: "1rem 0",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {item[0].image != "" ? (
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "contain",
                            }}
                            src={item[0].image}
                          />
                        ) : (
                          <></>
                        )}
                        <Box>
                          <Paragraph
                            color="#125396"
                            bold="500"
                            margin="0 0 0 .4rem"
                            center
                          >
                            {item[0].catalogue}
                          </Paragraph>
                          <Paragraph
                            color="#125396"
                            bold="500"
                            margin="0 0 0 .4rem"
                          >
                            {item[0].color}
                          </Paragraph>
                        </Box>
                      </Box>
                      {/* <Button
                        label={
                          <>
                            <ShoppingCart sx={{marginRight: "6px", fontSize: "20px"}} />
                            <Paragraph size="12px">ADD TO CART</Paragraph>
                          </>
                        }
                        color="#FFF"
                        bg="#125396"
                        size="14px"
                        pd=".6rem"
                        onClick={() => handleAddToCart()}
                        hover
                      /> */}
                      {/* <Paragraph bold="500" size="14px">QUANTITY</Paragraph> */}
                    </Box>
                    {item.map((item, index) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "1rem 0",
                        }}
                      >
                        {item.bestbuy == "1" ? (
                          <Box
                            sx={{
                              width: "18%",
                              padding: ".2rem .6rem",
                              position: "absolute",
                              top: "14px",
                              right: "5px",
                              zIndex: 1,
                            }}
                          >
                            <img
                              style={{ width: "100%" }}
                              src="../../assets/images/bestdeal.png"
                            />
                          </Box>
                        ) : (
                          <></>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box>
                            <Paragraph color="#125396" bold="500">
                              {item.size}
                            </Paragraph>

                            {/* {item.quantityavailable == 0 ? (
                              <Paragraph
                                style={{ fontStyle: "italic" }}
                                margin="0"
                                color="red"
                              >
                                Out of Stock
                              </Paragraph>
                            ) : (
                              <>
                                {item.quantityavailable < 10 &&
                                item.quantityavailable >= 1 ? (
                                  <Paragraph
                                    style={{ fontStyle: "italic" }}
                                    margin="0"
                                    color="red"
                                  >
                                    Call Customer Service
                                  </Paragraph>
                                ) : (
                                  <Paragraph
                                    style={{ fontStyle: "italic" }}
                                    margin="0"
                                    color="green"
                                  >
                                    {item.quantityavailable} stocks left
                                  </Paragraph>
                                )}
                              </>
                            )} */}
                            {item.quantityavailable == 0 && (
                              <Paragraph
                                style={{ fontStyle: "italic" }}
                                margin="0"
                                center
                                color="red"
                              >
                                Out of Stock
                              </Paragraph>
                            )}

                            {item.enablepurchase > 0 ? (
                              <Paragraph
                                style={{ fontStyle: "italic" }}
                                margin="0"
                                center
                                color="green"
                              >
                                {item.quantityavailable} stocks left
                              </Paragraph>
                            ) : (
                              <>
                                {item.quantityavailable < 10 &&
                                  item.quantityavailable >= 1 && (
                                    <Paragraph
                                      style={{ fontStyle: "italic" }}
                                      margin="0"
                                      center
                                      color="red"
                                    >
                                      Call Customer Service
                                    </Paragraph>
                                  )}
                              </>
                            )}

                            {item.quantityavailable > 10 && (
                              <Paragraph
                                style={{ fontStyle: "italic" }}
                                margin="0"
                                center
                                color="green"
                              >
                                {item.quantityavailable} stocks left
                              </Paragraph>
                            )}
                          </Box>
                          <Box sx={{ width: { xs: "44%", sm: "65%" } }}>
                            {checkCurrency == "MYR" && (
                              <Paragraph
                                style={{ fontStyle: "italic" }}
                                margin="0"
                                bold="600"
                                color={item.bestbuy == "1" ? "#FF00FF" : "#000"}
                              >
                                MYR {parseFloat(item.price).toFixed(2)}
                              </Paragraph>
                            )}

                            {checkCurrency == "SGD" && (
                              <Paragraph
                                style={{ fontStyle: "italic" }}
                                margin="0"
                                bold="600"
                                color={item.bestbuy == "1" ? "#FF00FF" : "#000"}
                              >
                                SGD {parseFloat(item.price).toFixed(2)}
                              </Paragraph>
                            )}

                            {checkCurrency == "USD" && (
                              <Paragraph
                                style={{ fontStyle: "italic" }}
                                margin="0"
                                bold="600"
                                color={item.bestbuy == "1" ? "#FF00FF" : "#000"}
                              >
                                USD {parseFloat(item.price).toFixed(2)}
                              </Paragraph>
                            )}
                          </Box>
                        </Box>
                        <Box sx={{ width: "35%" }}>
                          {item.quantityavailable == 0 || item.price == 0 ? (
                            <>
                              <FormInputNoLabel2
                                key={item.internalid}
                                disabled
                                border="1px solid #d7d7d7"
                                name="quantity"
                                onChange={(e) => handleChange(e, index, item)}
                                className="customTextField"
                                textalign="right"
                                // sx={{ width: { xs: "150px", md: "100%" } }}
                                id={
                                  item.internalid +
                                  "_" +
                                  item.size +
                                  "_" +
                                  item.price +
                                  "_" +
                                  item.single_price +
                                  "_" +
                                  productData.displayname +
                                  "_" +
                                  item.image +
                                  "_" +
                                  item.quantityavailable
                                }
                                value={item.quantity || null}
                                onKeyPress={(event) => {
                                  if (
                                    event.target.value == "" &&
                                    !/[1-9]/.test(event.key)
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              <input
                                value={item.internalid}
                                name="id"
                                onChange={(e) => handleChange(e, index)}
                                type="hidden"
                              />
                            </>
                          ) : (
                            <>
                              {item.enablepurchase > 0 ? (
                                <>
                                  <FormInputNoLabel2
                                    disabled={item.quantityavailable <= 0}
                                    key={item.internalid}
                                    border="1px solid #d7d7d7"
                                    name="quantity"
                                    onChange={(e) =>
                                      handleChange(e, index, item)
                                    }
                                    className="customTextField"
                                    textalign="right"
                                    id={
                                      item.internalid +
                                      "_" +
                                      item.size +
                                      "_" +
                                      item.price +
                                      "_" +
                                      item.single_price +
                                      "_" +
                                      productData.displayname +
                                      "_" +
                                      item.image +
                                      "_" +
                                      item.quantityavailable
                                    }
                                    value={item.quantity || null}
                                    onKeyPress={(event) => {
                                      if (
                                        event.target.value == "" &&
                                        !/[1-9]/.test(event.key)
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <FormInputNoLabel2
                                    key={item.internalid}
                                    disabled={item.quantityavailable < 10}
                                    border="1px solid #d7d7d7"
                                    name="quantity"
                                    onChange={(e) =>
                                      handleChange(e, index, item)
                                    }
                                    className="customTextField"
                                    textalign="right"
                                    id={
                                      item.internalid +
                                      "_" +
                                      item.size +
                                      "_" +
                                      item.price +
                                      "_" +
                                      item.single_price +
                                      "_" +
                                      productData.displayname +
                                      "_" +
                                      item.image +
                                      "_" +
                                      item.quantityavailable
                                    }
                                    value={item.quantity || null}
                                    onKeyPress={(event) => {
                                      if (
                                        event.target.value == "" &&
                                        !/[1-9]/.test(event.key)
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </>
                              )}
                              <input
                                value={item.internalid}
                                name="id"
                                onChange={(e) => handleChange(e, index)}
                                type="hidden"
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <br />
                </>
              ))}
            </Box>

            <br />
            <div className={classes.theSizeBtn}>
              <Button
                label="ADD TO CART"
                color="#FFF"
                bg="#125396"
                br="4px"
                pd=".6rem 1.5rem"
                onClick={() => handleAddToCart()}
                // onClick={() => handleCheckout()}
                hover
              />
            </div>
            <br />
            <br />
          </Section>
        </Container>
      )}
      <Footer />
      <Dialog
        open={openModal}
        // onClose={() => setOpenModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => handleCloseModal()}>
                <Close style={{ color: "#353535" }} />
              </a>
            </div>

            <br />
            <Paragraph
              center
              size="24px"
              color="#125396"
              bold="600"
              margin="0 0 1rem 0"
            >
              My Cart
            </Paragraph>

            <TableContainer style={{ height: "400px" }}>
              <Table>
                <TableHead className={classes.theHead}>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>Price</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>Quantity</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>Total</TableCell>
                    <TableCell sx={{ textAlign: "center" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentData != undefined && currentData.length > 0
                    ? currentData.map((item) => (
                        <TableRow>
                          <TableCell>
                            <div
                              style={{ display: "flex" }}
                              className={classes.theWidth}
                            >
                              <div className={classes.theIMGx}>
                                <img src={item.image} />
                              </div>
                              <div style={{ marginLeft: "1rem" }}>
                                <Paragraph
                                  margin="0 0 .2rem 0"
                                  bold="700"
                                  size="14px"
                                  className="_theWording"
                                >
                                  {item.name}
                                </Paragraph>
                                <Paragraph
                                  margin="0 0 .2rem 0"
                                  size="12px"
                                  className="_theWording"
                                >
                                  {item.brand}
                                </Paragraph>
                                <Paragraph
                                  margin="0 0 .2rem 0"
                                  size="12px"
                                  className="_theWording"
                                >
                                  {item.size}
                                </Paragraph>
                                <Paragraph
                                  margin="0 0 .2rem 0"
                                  size="12px"
                                  className="_theWording"
                                >
                                  {item.color}
                                </Paragraph>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.theWidth2}>
                              {checkCurrency == "MYR" && (
                                <Paragraph center>
                                  MYR {parseFloat(item.given_price).toFixed(2)}
                                </Paragraph>
                              )}
                              {checkCurrency == "SGD" && (
                                <Paragraph center>
                                  SGD {parseFloat(item.given_price).toFixed(2)}
                                </Paragraph>
                              )}
                              {checkCurrency == "USD" && (
                                <Paragraph center>
                                  USD {parseFloat(item.given_price).toFixed(2)}
                                </Paragraph>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.theWidth2}>
                              <div className={classes.theFlexQuantity}>
                                <div className={classes.theIncrement}>
                                  {count == 0 ? (
                                    <div className={classes.theBox}>
                                      <p className={classes.theCount}>0</p>
                                    </div>
                                  ) : (
                                    <div className={classes.theBox}>
                                      <p className={classes.theCount}>
                                        {item.quantity}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.theWidth2}>
                              {checkCurrency == "MYR" && (
                                <Paragraph center>
                                  MYR{" "}
                                  {parseFloat(item.given_subtotal).toFixed(2)}
                                </Paragraph>
                              )}
                              {checkCurrency == "SGD" && (
                                <Paragraph center>
                                  SGD{" "}
                                  {parseFloat(item.given_subtotal).toFixed(2)}
                                </Paragraph>
                              )}
                              {checkCurrency == "USD" && (
                                <Paragraph center>
                                  USD{" "}
                                  {parseFloat(item.given_subtotal).toFixed(2)}
                                </Paragraph>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>

            <br />

            <div className={classes.theFlexx}>
              <div className={classes.theRight}>
                <Button
                  // onClick={() => setOpenModal(false)}
                  href="/shop"
                  label="Continue Shopping"
                  br="4px"
                  color="#125396"
                  box="1px solid #125396"
                />
              </div>
              <div>
                <Button
                  href="/cart"
                  // onClick={() => handleCheckout()}
                  // onClick={() => handleProceed()}
                  label="Proceed to Checkout"
                  br="4px"
                  color="#fff"
                  bg="#125396"
                  hover
                />
              </div>
            </div>
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
